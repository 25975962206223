import * as React from "react";
import $ from 'jquery';
import {useEffect, useRef} from "react";


const HeaderAndMenu = () => {

    const cloned_menu = useRef(null);
    const responsive_menu = useRef(null);
    const responsive_menu_overlay = useRef(null);
    const menu_activated = useRef(false);

    const set_height = () => {
        console.log("set_height")
        let _menu = $('.zn-menu-visible').last();
        let height = _menu.css({height: 'auto'}).outerHeight(true);
        let window_height = $(window).height();
        let adminbar_height = 0;
        let admin_bar = $('#wpadminbar');

        // CHECK IF WE HAVE THE ADMIN BAR VISIBLE
        if (height < window_height) {
            height = window_height;
            if (admin_bar.length > 0) {
                adminbar_height = admin_bar.outerHeight(true);
                height = height - adminbar_height;
            }
        }
        _menu.attr('style', '');
        $('#page_wrapper').css({'height': height});
    };

    const launch_menu = () => {
        console.log("Responsive menu trigger clicked");
        responsive_menu.current.addClass('zn-menu-visible');
        set_height();
    }

    const start_responsive_menu = () => {
        console.log("Starting responsive menu");
        responsive_menu.current.find('a:not([rel*="mfp-"])').on('click', function (e) {
            $('.zn_res_menu_go_back').first().trigger('click');
        });

        // ADD ARROWS TO SUBMENUS TRIGGERS
        responsive_menu.current.find('li:has(> ul)').addClass('zn_res_has_submenu').prepend('<span class="zn_res_submenu_trigger glyphicon glyphicon-chevron-right"></span>');
        // ADD BACK BUTTONS
        responsive_menu.current.find('.zn_res_has_submenu > ul').addBack().prepend('<li class="zn_res_menu_go_back"><span class="zn_res_back_icon fas fa-chevron-left"></span><a href="#">' + 'Back' + '</a><a href="#" class="zn-close-menu-button"><span class="fas fa-times"></span></a></li>');

        // REMOVE BACK BUTTON LINK
        $('.zn_res_menu_go_back').click(function (e) {
            e.preventDefault();
            let active_menu = $(this).closest('.zn-menu-visible');
            active_menu.removeClass('zn-menu-visible');
            set_height();
            if (active_menu.is('#zn-res-menu')) {
                $('#page_wrapper').css({'height': 'auto'});
            }
        });

        // OPEN SUBMENU'S ON CLICK
        $('.zn_res_submenu_trigger').click(function (e) {
            e.preventDefault();
            $(this).siblings('ul').addClass('zn-menu-visible');
            set_height();
        });

        let closeMenu = function () {
            cloned_menu.current.removeClass('zn-menu-visible');
            $('.zn-res-trigger').removeClass('is-active');
        };
    }

    const installMenu = () => {
        let windowWidth = $(window).width();
        console.log("Installing menu for window width %d px", windowWidth);

        if (windowWidth < 1200) {
            console.log("Menu should be visible");
            if (!menu_activated.current) {
                start_responsive_menu();
                menu_activated.current = true;
            }
            $('#page_wrapper').addClass('zn_res_menu_visible');
        } else {
            console.log("Menu should NOT be visible");
            // WE SHOULD HIDE THE MENU
            $('.zn-menu-visible').removeClass('zn-menu-visible');
            $('#page_wrapper').css({'height': 'auto'}).removeClass('zn_res_menu_visible');
        }
    };

    const installResizeHandler = () => {
        $(window).on('load resize', function () {
            installMenu();
            let is = false;
            if ($(window).width() < 1200) {
                if (is) return;
                //@wpk
                // Close button for the responsive menu
                let closeMenuSender = $('.zn-close-menu-button, .zn-res-menu-overlay');
                if (closeMenuSender) {
                    closeMenuSender.on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        let parent = $('#zn-res-menu');
                        parent.removeClass('zn-menu-visible');
                        $('.zn-menu-visible', parent).removeClass('zn-menu-visible');
                        $('#page_wrapper').css({'height': 'auto'});
                    });
                }
                is = true;
            }
        });
    }

    const initializeMenuLayers = () => {
        cloned_menu.current = $('#main-menu > ul').clone().attr({id: "zn-res-menu", "class": ""});
        responsive_menu.current = cloned_menu.current.prependTo($('#page_wrapper'))
        responsive_menu_overlay.current = $('<div class="zn-res-menu-overlay"/>').insertAfter(cloned_menu.current);
    }

    useEffect(() => {
        initializeMenuLayers();
        installMenu();
        installResizeHandler();
    }, [])

    return <header id="header" className="site-header cta_button" data-header-style="1">
        <a name="home" id={"home"}/>
        <div className="site-header-wrapper">
            <div className="site-header-top-wrapper">
                <div className="siteheader-container container">
                    <div className="site-header-row site-header-top d-flex justify-content-between">
                        {/*<div className="site-header-top-left d-flex">
                            <ul className="topnav social-icons sc--clean align-self-center">

                                <li>
                                    <a href="https://twitter.com/RabiejMarcin/" title="Twitter">
                                        <i className="fab fa-twitter" style={{color:"#000"}}></i>
                                    </a>
                                </li>
                            </ul>
                            <div className="clearfix visible-xxs"></div>

                            <div className="kl-header-toptext align-self-center">

                            </div>
                        </div>*/}

                        <div className="site-header-top-right d-flex">
                           {/* <div className="topnav support--panel align-self-center">
                                <a className="topnav-item" href="/contact">
                                    <label htmlFor="support_p" className="topnav-item spanel-label">
                                        <i className="fas fa-info-circle support-info closed"></i>
                                        <i className="far fa-times-circle support-info opened"></i>
                                        <span className="topnav-item--text">SUPPORT & CONTACT</span>

                                    </label>
                                </a>
                            </div>*/}

                        {/*    <div className="topnav login--panel align-self-center">
                                <a className="topnav-item" href="https://app.mindyoga.ai/#/login">
                                    <i className="login-icon fas fa-sign-in-alt visible-xs xs-icon"></i>
                                    <span className="topnav-item--text">LOGIN</span>
                                </a>
                            </div>*/}
                        </div>
                    </div>

                    {/*<div className="separator dark"></div>*/}
                </div>
            </div>
            <div className="site-header-main-wrapper d-flex">
                <div className="siteheader-container container align-self-center">
                    <div className="site-header-row site-header-main d-flex flex-row justify-content-between">
                        <div className="site-header-main-left d-flex justify-content-start align-items-center">
                            <div className="logo-container logosize--yes">
                                <h1 className="site-logo logo" id="logo">
                                    <a href="/" title="MindYoga Daily Journal - ">
                                        <img src="/staticimg/logo/logowithtitle.png" className="logo-img" alt="MindYoga Daily Journal" title="MindYoga Daily Journal - your ultimate journaling companion"/>
                                    </a>
                                </h1>
                            </div>

                            <div className="separator visible-xxs"></div>
                        </div>

                        <div
                            className="site-header-main-center d-flex justify-content-center align-items-center">
                            <div className="main-menu-wrapper">
                                <div id="zn-res-menuwrapper">
                                    <a href="#" className="zn-res-trigger" onClick={launch_menu}></a>
                                </div>
{/*
                                <div id="main-menu" className="main-nav zn_mega_wrapper">
                                    <ul id="menu-main-menu" className="main-menu zn_mega_menu">
                                        <li className="menu-item"><a href="/#home">HOME</a></li>
                                        <li className="menu-item"><a href="/#faq">FAQ</a></li>


                                    </ul>
                                </div>
*/}
                            </div>
                        </div>

                        <div className="site-header-main-right d-flex justify-content-end align-items-center">


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>;
}

export default HeaderAndMenu;