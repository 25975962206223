import * as React from "react";
import {Helmet} from "react-helmet";

const BsHelmet = (props) => {

    return <Helmet>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="keywords"
              content="MindYoga Daily Journal - your ultimate journaling companion"/>
        <meta name="description"
              content="MindYoga Daily Journal - your ultimate journaling companion"/>
        <title>MindYoga Daily Journal - {props.title}</title>
        <link rel="canonical" href="https://mindyoga.ai"/>
        <meta name="robots" content="noodp,noydir"/>
        <meta property="og:url" content="https://mindyoga.ai"/>
        <meta property="og:image"
              content="https://mindyoga.ai/staticimg/logo/logosquare.png"/>
        <link rel="schema.DC" href="http://purl.org/DC/elements/1.0/"/>
        <meta name="DC.Title" content={"MindYoga Daily Journal - " + props.title}/>
        <meta name="DC.Creator" content="MindYoga.ai"/>
        <meta name="DC.Type" content="software"/>
        <meta name="DC.Date" content="2023-09-01"/>
        <meta name="DC.Format" content="text/html"/>
        <meta name="DC.Language" content="en"/>
        <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'/>

    </Helmet>;
}

export default BsHelmet