import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../fonts/font-awesome/css/font-awesome.min.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";

import MainHero from "../page_components/home/MainHero";
import ChatbotSetup from "../page_components/home/ChatbotSetup";
import Demo from "../page_components/home/Demo";
import Faq from "../page_components/home/Faq";
import TryItOutCallToAction from "../page_components/home/TryItOutCallToAction";
import SelfServeCustomerSupport from "../page_components/home/SelfServeCustomerSupport";
import CompatibleWith from "../page_components/home/CompatibleWith";
import Customers from "../page_components/home/Customers";
import Features from "../page_components/home/Features";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Home"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <MainHero/>
                {/*<Features/>*/}
                {/*<SelfServeCustomerSupport/>*/}
                {/*<ChatbotSetup/>*/}
                {/*<CompatibleWith/>*/}
                {/*<Demo/>*/}
                {/*<Customers/>*/}
                {/*<TryItOutCallToAction/>*/}
                {/*<Faq/>*/}
                <Footer/>
            </div>

        </ThemeProvider>
    )
}

export default IndexPage
