import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";

const Footer = () => {
    return (
        <footer id="footer" data-footer-style="1">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-5 mb-30">
                        <h3 className="title m_title">
                            MindYoga.ai
                        </h3>

                        <div className="sbs">
                            <ul className="menu">
                                {/*<li><a href="/#home">Home</a></li>*/}
                                {/*<li><a href="/pricing">Pricing</a></li>*/}
                                {/*<li><a href="https://help.roboassist.ai">Documentation</a></li>*/}
                                {/*<li><a href="/privacy">Privacy Policy</a></li>*/}
                                {/*<li><a href="/terms">Terms & Conditions</a></li>*/}
                                {/*<li><a href="/cookies">Cookie policy</a></li>*/}
                                {/*<li><a href="/contact">Contact Us</a></li>*/}
                            </ul>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 mb-30">
                        <div className="newsletter-signup">

                        </div>
                    </div>

                    <div className="col-sm-12 col-md-3 mb-30">
                        <h3 className="title m_title">
                            GET IN TOUCH
                        </h3>

                        <div >
                            <p>
                                Email: <a href="mailto:contact@mindyoga.ai">contact@mindyoga.ai</a><br/>
                            </p>

                        </div>
                    </div>
                </div>



            </div>
        </footer>
    )
        ;
}

export default Footer;