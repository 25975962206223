import * as React from "react";


const MainHero = () => {


    return (<div className="kl-slideshow static-content__slideshow maskcontainer--mask4">
            <div className="bgback">
            </div>
            <a name="home"></a>

            <div className="kl-slideshow-inner static-content__wrapper static-content--height">

                <div className="static-content__inner container">
                    <div className="kl-slideshow-safepadding sc__container">
                        <div className="static-content productzoom-style">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 mt-50 text-left pl-50 pr-50">
                                    <h4 className="static-content__subtitle text-lg-left text-center fs-l">
                                        <div className={"d-flex align-items-center justify-content-lg-start justify-content-center"}>
                                            <img src={"/staticimg/logo/journalcircleicon.png"} style={{width: "auto", height: "40px"}} alt={"MindYoga Daily Journal"}/>
                                            <span className="fw-extrabold fs-xl" style={{borderRadius: "10px"}}>MindYoga Daily Journal</span></div>
                                        <span className="fw-thin">
                                        <div className={" pt-10 fs-l pl-20"}>Your ultimate journaling companion</div>
                                        <br/>
										</span>
                                    </h4>
                                    <div>
                                        Guiding you through journaling, unpacking emotions, extracting insights, producing summaries, outputs to downloadable files (txt and markdown)
                                    </div>


                                    <div className={"d-flex justify-content-lg-start justify-content-center"}>
                                        <div className={"row mt-50"}>

                                            <div className={"col-12 d-flex p-0 ml-20"}>
                                            <span className={"mb-0 w-100"}>
                                                <a href="https://chat.openai.com/g/g-LB65GFC4T-mindyoga-daily-journal" target="_blank" className="btn btn-fullcolor fs-1rem">
                                                    <img src="/staticimg/openaiicon.png" alt={"OpenAI ChatGPT"} style={{width: "auto", height: "40px"}}/><span className={"font-weight-bold"}>MindYoga Daily Journal GPT</span>
                                                </a>
                                            </span>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-12 col-lg-6 mt-50 pl-50 pr-50">
                                    <h4 className="static-content__subtitle text-lg-left text-center fs-l">
                                        <div className={"d-flex align-items-center justify-content-lg-start justify-content-center"}>
                                            <img src={"/staticimg/logo/journalcircleicon.png"} style={{width: "auto", height: "40px"}} alt={"MindYoga Daily Journal"}/>
                                            <span className="fw-extrabold fs-xl" style={{borderRadius: "10px"}}>MindYoga Daily Journal Plus</span>
                                        </div>
                                        <span className="fw-thin">
                                        <div className={" pt-10 fs-l pl-20"}>Memory & export to Evernote and Notion</div>
                                        <br/>
										</span>
                                    </h4>
                                    <div><i className={"fa fa-check mr-1"}/>Records your journal entries in Evernote or Notion</div>
                                    <div><i className={"fa fa-check mr-1"}/>Weekly, monthly and annual summaries</div>
                                    <div><i className={"fa fa-check mr-1"}/>Set your custom journaling prompts</div>
                                    <div><i className={"fa fa-check mr-1"}/>Uses past sessions for <b>enhanced personal insight</b>, <b>continuity and context</b>, <b>goal tracking</b> and more</div>

                                    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>

                                    <div className={"d-flex justify-content-lg-start justify-content-center mt-50"}>
                                        <div className="newsletter-signup">

                                            <p>
                                                By subscribing to our mailing list you will be notified when MindYoga Daily Journal Plus is released. The first version will be released in Jan 2024.
                                            </p>
                                            <form
                                                action="https://mindyoga.us21.list-manage.com/subscribe/post?u=a9a59b35b2b1b6dfb0736a2d1&amp;id=76819bf0ca&amp;f_id=002deee6f0"
                                                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                                                className="validate"
                                                target="_blank" noValidate>
                                                <input type="email" defaultValue="" name="EMAIL" className="nl-email form-control"
                                                       id="mce-EMAIL"
                                                       placeholder="your.address@email.com" required/>
                                                <input type="submit" name="subscribe" className="nl-submit"
                                                       value="Notify me"/>

                                                <div style={{position: "absolute", left: "-5000px", ariaHidden: "true"}}><input
                                                    type="text"
                                                    name="b_a9a59b35b2b1b6dfb0736a2d1_76819bf0ca"
                                                    tabIndex="-1" defaultValue=""/></div>
                                            </form>


                                            <div id="notification_container">
                                                <div id="mce-responses" className="clear foot">
                                                    <div className="response" id="mce-error-response"
                                                         style={{display: "none"}}></div>
                                                    <div className="response" id="mce-success-response"
                                                         style={{display: "none"}}></div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MainHero;